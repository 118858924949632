import React, { useState, useEffect } from "react";
import "../../sass/style.scss";

import AboutBlackBox from "../aboutBlackBox/AboutBlackBox";

function Projects() {
  const [activeSlide, setActiveSlide] = useState(1); // Default to second slide

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };


  useEffect(() => {
    const handleWindowResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 930) {
        const inputs = document.querySelectorAll('.inputProj');
        inputs.forEach(input => {
          input.checked = true;
          input.disabled = true;
         
        });
      }
      else{
        const inputs = document.querySelectorAll('.inputProj');
        inputs.forEach(input => {
          input.checked = false;
          input.disabled = false;
          let anyChecked = false;
          inputs.forEach(input => {
            if (input.checked) {
              anyChecked = true;
            }
          });
          // If no input is checked, check the second one
          if (!anyChecked) {
            inputs[0].checked = true; // Index 1 represents the second input
          }
        });
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []); 

  return (
    <div>
      <span id="aboutBB"></span>
      <AboutBlackBox />
      <div className="wrapper">
        <div className="containerSlider">
          {[1, 2, 3, 4].map((index) => (
            <React.Fragment key={index}>
              <input
                className="inputProj"
                type="checkbox"
                name="slide"
                id={`c${index}`}
                checked={activeSlide === index}
                onChange={() => handleSlideChange(index)}
              />
              <label htmlFor={`c${index}`} className="card">
                <div className="row">
                  <div className="icon">{index}</div>
                  <div className="description">
                    <h4>
                      <strong>
                        {index === 1
                          ? "Snakeporium"
                          : index === 2
                          ? "Chat App"
                          : index === 3
                          ? "MyFlix"
                          : "Meet App"}

                        {index === 1 && (
                          <a
                          href="https://projects.yblackbox.com/snakeporium/snakeporium.html"
                            className="moreDetails"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here for more
                          </a>
                        )}
                        {index === 2 && (
                          <a
                            href="https://projects.yblackbox.com/chatApp/chat.html"
                            className="moreDetails"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here for more
                          </a>
                        )}
                        {index === 3 && (
                          <a
                            href="https://projects.yblackbox.com/myFlixProject/myflix.html"
                            className="moreDetails"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here for more
                          </a>
                        )}
                        {index === 4 && (
                          <a
                            href="https://projects.yblackbox.com/meetApp/meet.html"
                            className="moreDetails"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here for more
                          </a>
                        )}
                      </strong>
                    </h4>
                    <p className="explanation">
                      {index === 1
                        ? "An Angular web shop application with a SpringBoot API backend, and implements Gemini AI API to load more details for each product item."
                        : index === 2
                        ? "A chat app for mobile devices programmed using React Native. The app provides users with a chat interface and options to share images and their location."
                        : index === 3
                        ? "A React application that loads data from a MongoDB database using the MERN stack."
                        : "A progressive web application (PWA) programmed with React using a test-driven development (TDD) technique. The application uses the Google Calendar API to fetch upcoming events. "}
                    </p>
                    <p className="used">
                      {index === 1
                        ? "< Angular, Authentication, SpringBoot, Postman, Heroku >"
                        : index === 2
                        ? "< React Native, Firestone, Expo >"
                        : index === 3
                        ? "< React, Netlify, Node.js, Express.js >"
                        : "< React, AWS, Google Calendar API, Outh2, Jest, Enzyme, Puppeteer >"}
                    </p>
                  </div>
                </div>
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
