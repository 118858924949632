import React from "react";

import "../../sass/style.scss";
import boxVideo from "../../assets/video/boxVideo2.mp4";

function BlackBox() {
  return (
    <div className="headlineTop">
      <div className="headline left">
        <h1>
          Unleash <br />
          your <br />
          imagination <br />
        </h1>
      </div>
      <div className="headline video">
        <video loop autoPlay muted>
          <source src={boxVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="headline">
        <h1>
          Unlock the
          <br />
          magic of
          <br />
          <span className="y">Y</span>
          <span className="blackBoxLogo">BlackBox </span>
        </h1>
      </div>
    </div>
  );
}

export default BlackBox;
