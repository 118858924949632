import React from "react";

import "../../sass/style.scss";

export default function About() {
  return (
    <div className="about">
      <p>
        <strong className="titleAbout">
          From Beginner to Full Stack Developer: Meet the Mind Behind YBlackBox
        </strong>
        <br />
        <br /> I am Stephanie Schlachter, a talented and highly motivated web
        developer who is eager to embark on new career opportunities. With a
        passion for continuous learning and a drive to constantly improve my
        skills, I am ready to take the digital landscape by storm. So if you
        want to learn more about me and my journey in this dynamic field write
        me a message.
      </p>

      <br id="contact" />
    </div>
  );
}
