import React, { useState, useEffect, useRef } from "react";
import "../../sass/style.scss";
import logo1 from "../../assets/img/logo_pink2.svg";
import logo2 from "../../assets/img/logo_blue2.svg";
import logo3 from "../../assets/img/logo_green2.svg";
import logo4 from "../../assets/img/logo_peach2.svg";

import bar from "../../assets/img/header/bars-solid.svg";
import xImg from "../../assets/img/header/x-solid.svg";

function Header() {
  const imageArray = [logo1, logo2, logo3, logo4];

  const [currentIndex, setCurrentIndex] = useState(0);

  const logoRef = useRef(null);

  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    setScrollTop(window.scrollY);

    const newScrollTop = window.scrollY;
    setScrollTop(newScrollTop);

    const logoHeight = logoRef.current.clientHeight;
    const newTop = Math.max(0, 30 - (newScrollTop / logoHeight) * 50) + "%";
    const newHeight =
      Math.max(13, 60 - (newScrollTop / logoHeight) * 80) + "vh";

    logoRef.current.style.top = newTop;
    logoRef.current.style.height = newHeight;

    // Toggle visibility of nav based on logo height
    setIsNavVisible(newHeight === "13vh");
  };

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    console.log("Nav toggled!");
    const nav = document.querySelector(".nav");
    const link = document.querySelector(".hamburgerLinks");
    const image = document.querySelector(".hamburger");
    if (nav) {
      nav.classList.toggle("expanded");
      link.classList.toggle("visible");

      if (image.src.includes(bar)) {
        image.src = xImg; // Change to xImage if currently displaying bar
      } else if (image.src.includes(xImg)) {
        image.src = bar; // Change to bar if currently displaying xImage
      }
    }
  };

  const resetNav = () => {
    const nav = document.querySelector('.nav');
    const link = document.querySelector('.hamburgerLinks');
    const image = document.querySelector('.hamburger');
    if (nav && link && image) {
      nav.classList.remove('expanded');
      link.classList.remove('visible');
      image.src = bar; // Reset the image to bar
    }
  };

  const handleWindowResize = () => {
    resetNav();
  };

  window.addEventListener('resize', handleWindowResize);

  useEffect(() => {
    // Call handleScroll once after the component mounts
    handleScroll();

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
    }, 2000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [imageArray.length]);

  return (
    <div>
      <div className={`nav ${isNavVisible ? "visible" : ""}`}>
        <div className={`items-left ${isNavVisible ? "visible" : ""}`}>
          <a className="headerLink" href="#aboutbb">
            <strong>Y</strong>Projects
          </a>
          <a className="headerLink" href="#skills">
            <strong>Y</strong>Skills
          </a>
        </div>
        <div className="logo-container">
          <div className="logo" ref={logoRef}>
            {imageArray.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`Slide ${index}`}
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  opacity: index === currentIndex ? 1 : 0,
                  minHeight: "13vh", // Set the max height to 10vh
                }}
              />
            ))}
          </div>
        </div>

        <div className={`items-left ${isNavVisible ? "visible" : ""}`}>
          <a className="headerLink" href="#about">
            <strong>Y</strong>About
          </a>
          <a className="headerLink" href="#contact">
            <strong>Y</strong>Contact
          </a>
        </div>
        <div className="hamburgerMenu">
          <img
            src={bar}
            alt=""
            className={`hamburger ${isNavVisible ? "visible" : ""}`}
            onClick={toggleNav}
          />
        </div>
        <div className="hamburgerLinks">
          <a className="headerLink" href="#aboutbb" onClick={resetNav}>
            <strong>Y</strong>Projects
          </a>
          <a className="headerLink" href="#skills" onClick={resetNav}>
            <strong>Y</strong>Skills
          </a>
          <a className="headerLink" href="#about" onClick={resetNav}>
            <strong>Y</strong>About
          </a>
          <a className="headerLink" href="#contact" onClick={resetNav} >
            <strong>Y</strong>Contact
          </a>
        </div>
      </div>
      <div className="blur"></div>
      <div className="container "></div>
      <div className="content"></div>
    </div>
  );
}

export default Header;
