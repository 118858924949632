import React, { useEffect, useRef } from "react";

import "../../sass/style.scss";
import About from "../about/About";

import lottie from "lottie-web";
import characterAnim from "../../assets/data.json";

import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

import linkedin from "../../assets/img/contact/linkedin.svg";
import github from "../../assets/img/contact/github.svg";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c2oq54k",
        "template_o0ugfpc",
        form.current,
        "EN8tmj-G93IuOAs-N"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            // icon: 'success',
            confirmButtonColor: "#101010",
            title: "Message Sent Successfully",
          });
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            confirmButtonColor: "#101010",
            title: "Ooops, something went wrong",
            text: error.text,
          });
        }
      );
  };

  const animate = useRef(null);
  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: animate.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: characterAnim,
      id: "lottie-svg",
    });

    // let options = {
    //   threshold: 0.7,
    // };

    return () => instance.destroy();
  }, []);

  return (
    <div>
      <About />

      <div className="contactContainer">
        <div className="image-character" ref={animate}></div>

        <div className="contactForm">
          <h2 className="formHeader">Contact Me</h2>
          {/* <br /> */}

          <div className="socials">
            <a
              href="https://www.linkedin.com/in/stephanieschlachter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="socialLink" src={linkedin} alt="" />
            </a>
            <a
              href="https://github.com/sms165"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="socialLink" src={github} alt="" />
            </a>
            <a className="footerLink" href="mailto:contact@yblackbox.com">
              contact@yblackbox.com
            </a>
          </div>

          <div className="form">
            <form ref={form} onSubmit={sendEmail}>
              <input
                id="form-input-control-email"
                label="Email"
                name="from_email"
                placeholder="Email…"
                required
              />
              <br />
              <br />
              <input
                id="from_name"
                label="Name"
                name="from_name"
                placeholder="Name…"
                required
              />
              <br />
              <br />
              <input
                id="form-input-control-subject"
                label="Subject"
                name="subject"
                placeholder="Subject…"
                required
              />
              <br />
              <br />
              <textarea
                id="form-textarea-control-opinion"
                rows={8}
                label="Message"
                name="message"
                placeholder="Message…"
                required
              />
              <br />
              <br />
              <button className="formSubmit" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
