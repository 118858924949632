import React from "react";

import "../../sass/style.scss";
import Header from "../header/Header";

import Projects from "../projects/Projects";
import BlackBox from "../blackbox/BlackBox";

import Contact from "../contact/Contact";
import Skills from "../skills/Skills";



function Main() {
  return (
    <div>
      <div className="header">
        {/* Header Section */}
        <Header />
      </div>
      {/* <div className='bufferSpace'>
        <Buffer />
      </div> */}
      <div className="blackbox">
        <BlackBox />
      </div>
      <div className="projects" id="aboutbb">
        <Projects />
      </div>

      <div className="skills" id="skills">
        <Skills />
      </div>
      <div className="contact" id="about">
        <Contact />
      </div>
    </div>
  );
}

export default Main;
