import React from "react";

import "../../sass/style.scss";

import ae from "../../assets/img/skills/ae.svg";
import js from "../../assets/img/skills/js.svg";
import id from "../../assets/img/skills/id.svg";
import ai from "../../assets/img/skills/ai.svg";
import ps from "../../assets/img/skills/ps.svg";
import css from "../../assets/img/skills/css2.svg";
import bootstrap from "../../assets/img/skills/bootstrap.svg";
import git from "../../assets/img/skills/git.svg";
import html from "../../assets/img/skills/html.svg";
import react from "../../assets/img/skills/react.svg";
import node from "../../assets/img/skills/node.svg";
import php from "../../assets/img/skills/php.svg";
import sass from "../../assets/img/skills/sass.svg";
// import java from "../../assets/img/skills/java.svg";

export default function Skills() {
  return (
    <div className="skillsContainer">
      <span id="skills"></span>
      <br />
      <p>
        <em>
          "Problems are nothing but wake-up calls for creativity" – Gerhard
          Gschwandtner
        </em>
      </p>
      <br />
      <strong className="titleAbout">Skills</strong>
      <br />
      <br />
      <p className="skillsText">
        Enthusiastic in web development and eagerly honing skills to craft
        engaging and user-friendly digital experiences. Passionate about
        learning and leveraging creativity to solve challenges. Excited to
        contribute fresh perspectives and grow within the dynamic world of web
        development.
      </p>

      <br />
      <br />
      <strong className="subtitleSkills">Adobe</strong>
      <div className="iconsImgAdobe">
        <img className="icon" src={id} alt="" />
        <img className="icon" src={ps} alt="" />
        <img className="icon" src={ae} alt="" />
        <img className="icon" src={ai} alt="" />
      </div>
      <strong className="subtitleSkills">Web Development</strong>
      <div className="iconsImgStyle">
        <img className="icon" src={html} alt="" />
        <img className="icon" src={css} alt="" />
        <img className="icon" src={bootstrap} alt="" />
        <img className="icon" src={sass} alt="" />
        {/* </div> */}

        {/* <div className="iconsImgJs"> */}

        <img className="icon" src={js} alt="" />
        <img className="icon" src={react} alt="" />
        <img className="icon" src={node} alt="" />
        {/* </div> */}

        {/* <div className="iconsImgProg"> */}
        <img className="icon" src={php} alt="" />
        {/* <img className="icon" src={java} alt="" /> */}
        {/* </div> */}

        {/* <div className="iconsImg"> */}
        <img className="icon" src={git} alt="" />
      </div>

      <br />
    </div>
  );
}
