import React from "react";

import "../../sass/style.scss";

export default function AboutBlackBox() {
  return (
    <div className="aboutBB">
     
      <p>
        <strong className="titleAbout">
          Welcome to the fascinating world of web development, where creativity
          meets innovation and technology.
        </strong>
        <br />
        <br /> Discover a collection of projects crafted by YBlackBox,
        showcasing the skills and creativity of a budding full stack developer.
        From web applications to mobile solutions, explore the diverse range of
        projects created using a plethora of development tools and languages.
        Each project serves as a testament to the dedication and passion for
        technology that drives YBlackBox forward in the ever-evolving world of
        web development.
      </p>
      <br />
    </div>
  );
}
